<template>
    <div class="modal fade" id="loginModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <!--content-->
                <div v-cloak class="container-fluid" id="login">
                    <!-- login -->
                    <div v-if="ui.activeTab == 'login'" class="col-12 mx-auto pad-big marg-big">
                        <h2 class="align-center marg-big">Login</h2>
                        <div v-if="err.length > 0" class="col-12 mx-auto">
                            <div>Error:</div>
                            <ul>
                                <li v-for="(items, indx) in err" :key="indx">{{items}}</li>
                            </ul>
                        </div>
                        <div v-if="msg" class="align-center">
                            <div>{{msg}}</div>
                        </div>
                        <div v-if="!flags.loggedIn" class="row">
                            <div class="col-12 col-md-4">
                                <label for="email" id="username" name="username">Email</label>
                            </div>
                            <div class="col-12 col-md-8">
                                <input @keyup.enter="loginRequest()"
                                    id="email"
                                    class="w-100"
                                    v-model="login.form.email"
                                    type="email"
                                />
                            </div>
                            <div class="col-12 col-md-4">
                                <label for="pass">Password</label>
                            </div>
                            <div class="col-12 col-md-8">
                                <input @keyup.enter="loginRequest()"
                                    id="pass"
                                    class="w-100"
                                    v-model="login.form.password"
                                    type="password"
                                />
                            </div>
                            <div class="col-12 col-md-4"></div>
                            <div class="col-12 col-md-8">
                                <button type="button"
                                    class="btn btn-outline-primary w-100"
                                    @click="loginRequest()"
                                >
                                    login
                                </button>
                            </div>
                        </div>
                        <!-- register -->
                        <div v-if="!flags.loggedIn" class="col-12 mx-auto marg-big">
                            <a @click="()=>ui.activeTab = 'register'"
                                class="clickable"
                                style="opacity:.7"
                            >
                                &#8226; Don't have an account?
                            </a>
                            <a @click="()=>ui.activeTab = 'forgotPassword'"
                                class="clickable"
                                style="opacity:.7; margin-left:1em;"
                            >
                                &#8226; Forgot password?
                            </a>
                            <router-link
                                class="clickable"
                                style="opacity:.7; margin-left:1em;"
                                to="policy"
                            >
                                &#8226; Policy
                            </router-link>
                        </div>
                        <div v-if="flags.loggedIn">
                            <div class="col-12 col-md-8 mx-auto">
                                <a @click="reload()">
                                    <button type="button" class="btn btn-outline-primary w-100">Force reload</button>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div v-else-if="ui.activeTab == 'register'" class="col-12 mx-auto pad-big marg-big">
                        <h2 class="align-center marg-big">Register</h2>
                        <div v-if="err.length > 0" class="col-12 mx-auto">
                            <div>Error:</div>
                            <ul>
                                <li v-for="(items, indx) in err" :key="indx">{{items}}</li>
                            </ul>
                        </div>
                        <div v-if="msg" class="align-center">
                            <div>{{msg}}</div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-md-4">
                                <label name="username" id="username">Email</label></div>
                            <div class="col-12 col-md-8">
                                <input @keyup.enter="registerRequest()" class="w-100" v-model="register.form.email" />
                            </div>

                            <div class="col-12 col-md-4">
                                <label>Username</label></div>
                            <div class="col-12 col-md-8">
                                <input @keyup.enter="registerRequest()" class="w-100" v-model="register.form.username" />
                            </div>

                            <div class="col-12 col-md-4">
                                <label>Password</label></div>
                            <div class="col-12 col-md-8">
                                <input @keyup.enter="registerRequest()" class="w-100" v-model="register.form.password" type="password" />
                            </div>

                            <div class="col-12 col-md-4">
                                <label>Confirm password</label></div>
                            <div class="col-12 col-md-8">
                                <input @keyup.enter="registerRequest()" class="w-100" v-model="register.form.password2" type="password" />
                            </div>
                            <div class="col-12 col-md-4"></div>
                            <div class="col-12 col-md-8">
                                <button
                                    type="button"
                                    class="btn btn-outline-primary w-100"
                                    @click="registerRequest()"
                                >
                                    register
                                </button>
                            </div>
                        </div>
                        <div class="col-12 mx-auto marg-big">
                            <a @click="()=>ui.activeTab = 'login'" class="clickable" style="opacity:.7">&#8226; Back to login</a>
                        </div>
                    </div>
                    <div v-else-if="ui.activeTab == 'forgotPassword'" class="col-12 mx-auto pad-big marg-big">
                        <h2 class="align-center marg-big">Forgot Password</h2>
                        <div class="row">
                            <div class="col">
                                <div>Only manual resets are available right now. Contact admin using <span underline>raionus#8576</span> via Discord.</div>
                                <div class="w-100 marg-big align-center"><a href="https://discord.com/"><button class="btn btn-outline-primary">Link to Discord App</button></a></div>
                            </div>
                        </div>
                        <div class="col-12 mx-auto marg-big">
                            <a @click="ui.activeTab = 'login'" class="clickable" style="opacity:.7">&#8226; Back to login</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
</template>

<script>
import C from '@/assets/common'
import ApiPreloader from '@/assets/preloader'

const {
    UseAPI, HandleRequestFail, SendUserAlert
} = C

export default {
    name: 'Auth',
    data() {
        return {
            err: [],
            msg: "",
            status: "",
            ui: {
                activeTab: 'login'
            },
            login: {
                form: {
                    email: '',
                    password: '',
                }
            },
            register: {
                form: {
                    email: '',
                    password: '',
                    password2: '',
                    username: '',
                    transferRequest: false
                }
            },
            flags: {
                loggedIn: false
            },
        }
    },
    methods: {
        GetLogin(obj) {
            if (!('password' in obj) || !('email' in obj)) { alert('Please add a username and password'); return false }
            const { password, email } = obj
            return UseAPI('/login', { auth: false, method: "POST", body: JSON.stringify({ email, password }) })
            .then((result) => {
                console.log(result)
                if (result.success) {
                    SendUserAlert('login successful', 'alert-success')
                    localStorage.setItem('token', result.token)
                    localStorage.setItem('refreshToken', result.refreshToken)
                    return true
                }
                    return false
            })
            .catch((result) => {
                alert('Login attempt failed. Please try again.')
                console.log(result)
            })
        },
        GetRegister(obj) {
            const {
            username, email, password, password2, transferRequest
            } = obj
            console.log('received', obj)
            if (!username || !email || !password || !password2) {
                alert('Please fill out all the fields');
                return false
            }
            return UseAPI('/register',
            {
                auth: false,
                method: "POST",
                body: JSON.stringify({
                email, username, password, password2, transferRequest
                })
            })
            .then((result) => result)
            .catch((result) => result)
        },
        async loginRequest() {
            if (!this.login.form.email || !this.login.form.password) { alert('Please enter an email and password.'); return false }
            const x = await this.GetLogin({ email: this.login.form.email, password: this.login.form.password })
            if (x) {
                this.msg = "Login successful. Refreshing momentarily..."
                this.flags.loggedIn = true
                ApiPreloader.clearApiData()
                window.setTimeout(() => {
                    window.location.href.search('welcome') === -1
                        ? this.reload()
                        : this.$router.push({ path: '/' })
                }, 1500)
            } else {
                this.msg = "Login failed. Double-check that your credentials are accurate."
            }
            return x
        },
        reload() {
            this.$router.go()
        },
        registerRequest() {
            const {
                email, password, password2, username, transferRequest
            } = this.register.form
            return this.GetRegister({
                email, password, password2, username, transferRequest
            })
            .then((res) => {
                this.handleReturn(res)
            })
        },
        handleReturn(x) {
            this.msg = 'msg' in x ? x.msg : ''
            this.err = 'err' in x ? x.err : []
            this.status = 'status' in x ? x.status : false
            if (this.status === 1) {
                alert('Account created.\nPlease login')
            }
            return { msg: this.msg, err: this.err, status: this.status }
        },
        forgotPassword() {
            alert('Only manual resets are available at the moment. Contact admin through raionus#8576 on Discord')
        }
    },
    created() {
        console.log('%cCreated Auth', window.ConsoleStyles.createdComponent, this)
        window.loginModal = this
    }
}
</script>

<style lang="sass" scoped>
</style>
