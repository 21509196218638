<template>
    <div class="home">
        <Auth/>
        <div class="container-fluid">
            <div class="col-12 col-lg-11 col-xl-10 mx-auto">
                <router-view
                v-on:alert="newAlert($event)"
                ></router-view>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import VueHotkey from 'v-hotkey'
import Auth from '@/components/Login.vue'

Vue.use(VueHotkey)
export default {
    name: 'Generic',
    components: {
        Auth,
    },
    methods: {
        newAlert(v) {
            console.log(v)
        }
    },
    data() {
        return {
            alerts: [],
        }
    },
}
</script>
